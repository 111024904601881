/* Whole page */
.App {
  text-align: center;
  height:100%;
  padding: 0;
  margin: 0;
  background-color: #FFFFFF;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}


.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

/* Body Content */

.body-grey {
  background-color:#F0F0F0;
}

.body-white {
  background-color: #FFFFFF;
}

/* Home Content */

#quote-author {
  text-align: right;
  
}

#quote-table {
  display: inline-block;
  align-content: center;
}

table, table > tbody > th, table > tbody > tr, table > tbody > tr > td {
  background-color: #F0F0F0;
  border: none !important;
  border-bottom: none !important;
  border-top: none !important;
  border-color: #F0F0F0;
}

.ui.table td {
  padding: 0 0 0 0;
}

#jason-img {
  display: inline-block;
  align-content: center;
  margin-bottom: 8px;
}

.ContentPage {
  padding-top: 40px;
}

.left.aligned {
  text-align: left;
}



/* Footer */

#footer > a > i, #footer > i {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
  padding-left: 10px;
  height:100%;
  vertical-align: middle;
}

#footer-jason {
  color: white;
}

#footer {
  text-align: center;
  vertical-align: center;
  background-color: #1b1c1d;
  clear:both;
}

.App {
  -webkit-display: flex;
  -moz-display: flex;
  -ms--display: flex;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.ContentPage {
  -webkit-display: flex;
  -moz-display: flex;
  -ms--display: flex;
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  width: 100%;
  min-width: 100vw;
}

.padding {
  padding-top: 8px;
  padding-bottom: 8px;
}

.contact {
  padding-top: 8px;
}

#mobile-project .image-gallery {
  width: 50%;
}

ul {
  columns: 2;
}